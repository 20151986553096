@import '../../style/varibale';


.slick-dots li button {
    color: $mainColor !important;
}

.slick-dots li button:before {
    color: $mainColor !important;
    font-size: 10px !important;
}

.app__slider-home {
    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }
}

.carousel-indicators {
    .active {

        background-color: #FA6409 !important;
    }
}

.app__silder-home {
    overflow-y: hidden;
    height: 100vh;

    .slick-dots {
        bottom: 15px !important;
    }

}

.slider__home {
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: -5 !important;
}

.app__slider-home {
    position: relative !important;

}

.content__silder {
    position: absolute;
    // z-index: 6999999999999999999999999999 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
    background-color: rgba($color: #000000, $alpha: 0.4) !important;

    .content {
        width: fit-content;
        padding: 15px 50px;
        text-align: left;
        border-radius: 0px 10px 10px 0px;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        background-color: rgba($color: #000000, $alpha: 0.4) !important;
        border-left: 5px solid #FA6409;

        @media screen and (max-width:464px) {
            padding: 15px;
        }

        span {

            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            margin-bottom: 15px;
            text-transform: capitalize;
            color: #FFFFFF;

            @media screen and (max-width:767px) {
                font-size: 35px !important;
            }

            @media screen and (max-width:464px) {
                font-size: 25px !important;
            }
        }

        p {
            font-size: 36px;
            line-height: normal;
            color: #FFFFFF !important;
            margin-bottom: 50px;

            @media screen and (max-width:767px) {
                // font-weight: 600;
                line-height: normal;
                margin-bottom: 30px;
                margin-top: 15px;
                font-size: 20px !important;
            }
        }

        /* CSS */
        .button-86 {

            all: unset;
            width: 100px;
            height: 30px;
            font-size: 16px;
            background: transparent;
            border: none;
            position: relative;
            color: #f0f0f0;
            cursor: pointer;
            z-index: 1;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;

        }

        .button-86::after,
        .button-86::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -99999;
            transition: all .4s;
        }

        .button-86::before {
            transform: translate(0%, 0%);
            width: 100%;
            height: 100%;
            background: $mainColor;
            border-radius: 5px;
        }

        .button-86::after {
            transform: translate(10px, 10px);
            width: 35px;
            height: 35px;
            background: #ffffff15;
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border-radius: 50px;
        }

        .button-86:hover::before {
            transform: translate(5%, 20%);
            width: 110%;
            height: 110%;
        }

        .button-86:hover::after {
            border-radius: 5px;
            transform: translate(0, 0);
            width: 100%;
            height: 100%;
        }

        .button-86:active::after {
            transition: 0s;
            transform: translate(0, 5%);
        }
    }
}

.content__silder-rtl {

    display: flex;
    justify-content: center;
    flex-direction: column;

    .content {


        margin-left: auto !important;
        display: flex;
        justify-content: start;
        flex-direction: column;
        text-align: right;

        width: fit-content;
        padding: 15px 50px;
        // border-radius: 10px 0px 0px 10px; 
        background-color: rgba($color: #000000, $alpha: 0.4) !important;
        // border-right: 4px solid #FA6409;
        // border-left: 2px solid transparent !important;

        span {
            text-align: right;

        }

        p {
            text-align: right;

        }

        .link__home {
            width: fit-content !important;
        }

        /* CSS */
        .button-86 {
            text-align: right;
            all: unset;
            width: 100px;
            height: 30px;
            font-size: 16px;
            background: transparent;
            border: none;
            position: relative;
            color: #f0f0f0;
            cursor: pointer;
            z-index: 1;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;

        }

        .button-86::after,
        .button-86::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -99999;
            transition: all .4s;
        }

        .button-86::before {
            transform: translate(0%, 0%);
            width: 100%;
            height: 100%;
            background: $mainColor;
            border-radius: 10px;
        }

        .button-86::after {
            transform: translate(10px, 10px);
            width: 35px;
            height: 35px;
            background: #ffffff15;
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border-radius: 50px;
        }

        .button-86:hover::before {
            transform: translate(5%, 20%);
            width: 110%;
            height: 110%;
        }

        .button-86:hover::after {
            border-radius: 10px;
            transform: translate(0, 0);
            width: 100%;
            height: 100%;
        }

        .button-86:active::after {
            transition: 0s;
            transform: translate(0, 5%);
        }
    }

}


.app__project-home {
    padding-top: 50px !important;
}

.body__overlay {
    font-family: 'Cairo', sans-serif !important;
    font-style: normal;
    font-weight: lighter !important;
    font-size: 15px;
    line-height: 22px;
    text-transform: capitalize;
    color: #ffffffdb;
}

.title__overlay {
    font-family: 'Cairo', sans-serif !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.btn__overlay {
    font-family: 'Cairo', sans-serif !important;
    font-style: normal !important;
    font-weight: lighter !important;
    font-size: 13px !important;
    line-height: 21px !important;
    text-align: center !important;
    text-transform: capitalize !important;
    color: #FFFFFF !important;

}

.loading__page {
    position: relative;

    // z-index: 15555555555555555555555555555555 !important;
    .loading {
        position: absolute;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: rgba($color: #FFFFFF, $alpha: 0.9) !important;
        z-index: 999999999999 !important;
    }
}