@import '../style/varibale';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');

body {
  // background: $colorLight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #EDF2F8; 
  background-color: white;
  font-family: 'Cairo', sans-serif !important;
}
.bg-light2{
  background-color: #F7FAFC !important;
  object-fit: contain;
}
.object__fit__contain{
  object-fit: contain !important;
}
.object__fit__cover{
  object-fit: cover !important;
}
.bg_light1{
  background-color: white !important;
}

::-webkit-scrollbar {
  width: 7px; /* عرض الشريط العمودي */
  height: 2px; /* عرض الشريط الجانبي */
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $secondColor;
  border-radius: 10px;
  // border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: $secondColor, $alpha: 0.8);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.menuIcons {
  font-size: 15px !important;
}

a {
  text-decoration: none !important;
}

.app__navbar-mt {
  padding-top: 115px !important;
}


p { 
  font-style: normal !important;
  font-weight: 400 !important;
  text-transform: capitalize;
  color: #404040 !important;
  // text-align: justify !important;
  text-justify: inter-word !important;
}

h1 {
  
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  text-transform: capitalize;
} 
