@import '../../style/varibale';

.app__blogs {
    margin-top: 140px !important;

    .app_blogs-content {
        margin-top: 20px !important;

        .content {
            background-color: white;
            border-radius: 5px;
            padding: 10px 20px 20px 20px;
            transform: translateY(-40%);
            width: 90%;
            margin: auto;

            .header {
                h5 {
                    color: #404040;
                    font-size: 25px;

                    font-weight: 600;
                    text-transform: capitalize;
                }
            }

            .body {
                p {
                    color: rgba(64, 64, 64, 0.52);
                    font-size: 16px;

                    text-transform: capitalize;
                }
            }
        }
    }
}

.app__blogs-details {
    min-height: 500px !important;
    width: 100% !important;
}

.app__blogs-details-centent {
    margin-top: 50px;

    h1 {
        color: $mainColor;

        @media screen and (max-width:767px) {
            font-size: $fontSize-25;
        }
    }

    p {
        color: #404040;

        letter-spacing: 1px;
        text-transform: capitalize;

        @media screen and (max-width:767px) {
            font-size: $fontSize-16;
        }
    }
}

.blog_details_data {

    h1,
    h3 {
        color: #383B52;
        font-family: Cairo;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 1200px) {
            font-size: 30px;

        }

        @media screen and (width < 900px) {
            font-size: 30px;

        }

        @media screen and (width < 700px) {
            font-size: 20px;

        }
    }
    img{
        width: 100%;
    }
    span {
        color: #C4AB73;
        font-family: Cairo;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    p {
        color: #282828;
        font-family: Cairo;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        /* 320.833% */
        text-transform: capitalize;
    }


    ul {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
            span {
                background-color: transparent !important;

                @media screen and (width < 1200px) {
                    font-size: 30px;

                }

                @media screen and (width < 900px) {
                    font-size: 30px;

                }

                @media screen and (width < 700px) {
                    font-size: 16px;

                }
            }
        }
    }
}

ul {
    li {
        color: #000;
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 767px) {
            font-size: 14px; 
            font-weight: 400;
        }
    }
}

ul {
    list-style: disc !important;

    @media screen and (width < 767px) {
        padding: 15px !important;
    }

    li {
        color: #282828;
        font-family: $fontBase;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 767px) {
            font-size: 14px;
        }
    }
}