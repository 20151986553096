@import '../../style/varibale';

.navbar-navOnthorPAge { 
    // background-color: #1f1f1f58;
    // -webkit-backdrop-filter: blur(7px);
    // backdrop-filter: blur(7px);
    // backdrop-filter: blur(7px);
    // -webkit-backdrop-filter: blur(7px);
}

.navbar-nav {
    // background-color: #1f1f1f58;
    // -webkit-backdrop-filter: blur(7px);
    // backdrop-filter: blur(7px);
    // backdrop-filter: blur(7px);
    // -webkit-backdrop-filter: blur(7px);
}

.fixed-top {
    top: 35px !important;
}

.main-nav {

    nav {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.navbar__social {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 88888;
    background-color: $mainColor !important;
    height: 35px !important;

    &.hide {
        display: none;
    }

    .navbar__social-Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        background-color: $mainColor !important;

        .calling {
            display: flex;
            gap: 60px;
            align-items: center;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 1px;
                height: 35px;
                background-color: #1F1F1F;
                left: 50%;
            }

            svg {
                width: 15px !important;
                height: 15px !important;
            }

        }

        .center {
            display: flex;
            gap: 50px;
            align-items: center;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 1px;
                height: 35px;
                background-color: #1F1F1F;
                left: 35%;
            }

            svg {
                width: 15px !important;

            }

            .nav1__home_svg {
                svg {
                    width: 15px !important;
                }
            }

            .change-lang {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                user-select: none;

                .text {
                    color: $colorLight;
                }

                .lang {
                    path {
                        fill: #fff !important;
                    }
                }
            }
        }

        .center-rtl {
            &::after {
                left: 65% !important;
            }
        }

        .social {
            display: flex;
            gap: 25px;

            @media screen and (max-width:700px) {
                display: none;
            }

            position: relative; 

            svg,
            .facebook {
                width: 16px !important;
                height: 16px !important;
            }


        }
    }

}

.Logo__RightClick {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        @media screen and (max-width:767px) {
            width: 100px !important;
            height: 40px;
        }
    }

    // font-weight: bolder;
    // color: $colorLight !important;
    // font-family: Combo, cursive;
    // font-size: 2.3rem;
    // font-weight: 600;
    margin: 0 !important;
    padding: 0 !important;
    // text-align: center;
    // text-transform: capitalize;
    // -webkit-user-select: none;
    // user-select: none;
    // cursor: pointer;

}

.icons__Close {
    width: 30px;
    cursor: pointer;

    &:hover {
        line {
            transition: all 0.5s;
            stroke: $mainColor;
        }
    }
}

.icons__menu {
    width: 30px;
    cursor: pointer;

    line {
        transition: all 0.5s;
        stroke: $colorLight;
    }

    &:hover {
        line {
            transition: all 0.5s;
            stroke: $mainColor;
        }
    }

    // &:hover {}
}

.icons__Close {
    margin-top: 30px;

    &:hover {
        circle {
            transition: all 0.5s;
            stroke: $mainColor;
        }
    }
}

.app__closeIcons {
    margin-bottom: 50px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.app__menu {
    z-index: 2 !important;
    position: absolute;
    right: 0px;
    top: 0%;
    background: #121212;
    width: 250px;
    overflow: scroll;
    overflow-x: hidden;
    min-height: 100vh;

    @media screen and (max-width:400px) {
        width: 250px;
    }
}

.app__menu-rtl {
    z-index: 2 !important;
    position: absolute;
    left: 0px;
    top: 0%;
    background: #121212;
    width: 250px;
    overflow: scroll;
    overflow-x: hidden;
    min-height: 100vh;

    @media screen and (max-width:400px) {
        width: 250px;
    }
}

.routes {
    display: flex;
    flex-direction: column;

    .link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #FFFFFF;
        transition: all 0.1s;
        height: 70px;
        width: 100% !important;
        background: #0E0E0E;
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 20px;
            right: 10%;
            background-color: #0E0E0E;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            opacity: 0;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            bottom: 0;
            background-color: $mainColor;
            width: 0%;
            height: 100%;

        }

        .icon {
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-60%);

            path {
                transition: all 0.6s;
                fill: #0E0E0E;
                stroke: #0E0E0E
            }

            rect {
                fill: #0E0E0E;
            }
        }

        .line {
            z-index: 99999999;

            path {
                transition: all 0.6s;
                stroke: #0E0E0E
            }

        }

        .link_text {

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: -20px;
                right: 15px;
                background-color: #0E0E0E;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                opacity: 0;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -20px;
                left: -15px;
                background-color: #0E0E0E;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                opacity: 0;

            }

        }

        &:hover {
            color: $colorLight !important;


            .icon {
                path {
                    transition: all 0.6s;
                    fill: $colorLight;
                    stroke: $colorLight
                }

                rect {
                    fill: $colorLight;
                }

            }

            .line {
                z-index: 99999999;

                path {
                    stroke: $colorLight !important
                }

            }

            .link_text {
                transition: all 1s;

                &::before {
                    transition: all 0.5s;
                    opacity: 1;
                    background-color: white;
                }

                &::after {
                    transition: all 0.5s;
                    opacity: 1;
                    background-color: white;
                }
            }

            &::after {
                opacity: 1;
                background-color: white;
            }

            &::before {
                transition: all 0.6s;
                width: 100%;
            }
        }

    }



}


.link.active {
    color: $colorLight !important;
    background-color: $mainColor;

    &::after {
        opacity: 1;
        background-color: white;
    }

    .icon {
        path {
            fill: $colorLight;
            stroke: $colorLight
        }

        rect {
            fill: $colorLight;
        }
    }

    .line {
        path {
            transition: all 0.6s;
            stroke: $colorLight
        }

    }

    .link_text {

        &::before {
            opacity: 1;
            background-color: white;
        }

        &::after {
            opacity: 1;
            background-color: white;
        }
    }
}


.navbar__social {
    /* Existing styles */
    transition: all 0.5s;

    &.hidden {
        display: none;
    }
}

.fixed-top {
    /* Existing styles */
    transition: all 0.5s;

    &.scroll {
        background: rgba(255, 255, 255, .9) !important;

        top: 0px !important;

        .Logo__RightClick {
            color: $mainColor !important;
        }

        .icons__menu {
            line {
                stroke: $mainColor;
            }
        }

        .app__menu-talk {
            background-color: $mainColor;
            color: $colorLight;

            &:hover {
                background-color: $colorDark;
                color: #FFFFFF;
            }
        }
    }
}

.app__menu-left {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .app__menu-talk {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: $mainColor;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        color: $colorLight;
        border-radius: 4px;
        padding: 6px 15px 6px 20px;
        cursor: pointer;
        user-select: none;
        transition: all 0.5s;
        font-size: 15px;

        &:hover {
            background-color: $colorLight;
            color: $mainColor;
        }
        .spinner-grow{
             width: 10px !important;
             height: 10px !important;
        }
    }
}

.main-nav {
    nav {
        height: 67px !important;
    }
}

.navbar-sm-icons-phone {
    path {
        fill: white;
    }
}