@import './varibale';
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');



.bg_image {
  height: 100vh;
}

.image_aboutus {
  position: relative;
}

// pagr not found
.app__error {
  display: flex;
  justify-content: center;
  align-items: center;

  .error_payer {
    @media screen and (width < 767px) {
      width: 100% !important;
    }
  }
}

// .overlay {
//   position: absolute;
//   top: 0;
//   left: 0; 
//   bottom: 0;
//   right: 0; 
//   opacity: 1; 
//   pointer-events: none;
// }

// .image_aboutus  .overlay {
//   background-color: #FA6409 !important;    
//   opacity: 0.3;
// }

.group_scoial-media,
.group_scoial-media-notPosotions {
  display: flex;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  z-index: 1 !important;

  svg {
    height: 20px !important;
    width: 26px !important;

    &:hover {
      path {
        transition: all 0.5s;
        fill: $mainColor;
      }
    }

    @media screen and (max-width:767px) {
      height: 18px !important;
      width: 18px !important;
    }
  }
}

.group_scoial-media {
  flex-direction: column;
  gap: 50px;
  position: fixed;
  right: 40px;
  bottom: 20px;

  @media screen and (max-width:767px) {
    right: 20px;
    gap: 30px !important;
  }
}

.group_scoial-media-notPosotions {
  flex-direction: row;
  gap: 15px;

  @media screen and (max-width:767px) {
    gap: 30px !important;
  }
}

.group_Calling {
  img {
    width: 35px;
    height: 35px;
  }

  z-index: 99999;
  gap: 25px !important;
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  position: fixed;
  left: 35px;
  bottom: 20px;

  @media screen and (max-width:767px) {
    flex-direction: column;
    left: 20px;

  }

  .phoneIcons {
    transition: all 0.5s;
    // background-color: white;
    padding: 12px;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      // background-color: $footerColor;

      svg {
        path {
          fill: $mainColor;
        }
      }
    }
  }
}

.whatDark {
  img {
    width: 35px;
    height: 35px;
  }

  z-index: 99999;
  gap: 25px !important;
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  position: fixed;
  right: 35px !important;
  bottom: 20px;

  // box-shadow: ;
  @media screen and (max-width:767px) {
    flex-direction: column;
    left: 20px;

  }

  .phoneIcons {
    transition: all 0.5s;
    // background-color: white;
    padding: 12px;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      // background-color: $footerColor;

      svg {
        path {
          fill: $mainColor;
        }
      }
    }
  }

}

.group_Calling-contactus {
  position: static !important;

  .phoneIcons {
    transition: all 0.5s;
    background: #E9E9E9 !important;
  }

  margin-bottom: 45px;
  margin-top: 15px;
}




.app__title {

  h1 {
    font-family: 'Cairo', sans-serif !important;
    font-size: 32px !important;
    color: #404040;

  }

  p {
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400;
    color: #6F6F6F !important;

  }


  @media screen and (max-width:767px) {
    h1 {
      font-weight: 700;
      font-size: 32px !important;
      line-height: 50px;
    }

    p {
      font-weight: 400;
      width: 100%;
      font-size: 12px !important;
      line-height: normal !important;
      margin-top: 0px !important;

    }
  }
}

// .app__title {
//   p {
//     margin: auto;
//     width: 60%;
//     // line-height: 1.8;
//   }

//   @media screen and (max-width:600px) {
//     h1 {
//       font-size: 30px !important;
//       margin-bottom: 0px !important;
//     }

//     p {
//       margin: auto;
//       width: 98%;
//       font-size: 16px !important;
//     }
//   }

//   @media screen and (max-width:800px) {
//     p {
//       font-size: 16px !important;
//       width: 98%;
//     }
//   }
// }