@import '../../../style/varibale';

.app__blogs-home { 
    padding: 0;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    .app__blogs-left {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }
    .slick-dots{
        // background-color: red !important;
        bottom: 10px !important;
    }
}

.sliders-blogs {
    .slider__item {
        margin-top: 20px !important;

        .content {
            background-color: white;
            border-radius: 5px;
            padding: 10px 20px 20px 20px;
            transform: translateY(-40%);
            width: 90%;
            margin: auto;

            .header {
                h5 {
                    color: #404040;
                    font-size: 20px;
                    
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }

            .body {
                p {
                    color: rgba(64, 64, 64, 0.52);
                    font-size: 12px !important;
                    
                }
            }
        }
    }
} 

.app__feedback {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $colorLight-0 !important;

    .container {
        @media screen and (max-width:767px) {
   
            width: 100% !important;
        }
    }
}

.slider__item {
    padding-right: 20px !important;

  
}