@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,500&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:wght@200;300;400;500;600;700&family=Roboto:wght@700;900&family=Tajawal:wght@300;400;500;700;800;900&display=swap');
// colors
$mainColor: #FA6409;
$footerColor: #24282C;
$secondColor:#FA6409 ;
$colorGrey-0: #404040;
$colorGrey-1: #999999;
$colorDark: #272727FA;
$colorLight: #F9F9F9;
$colorLight-0: #FFFFFF;
$colorLight-1: #F7F9FB; 

// font size
$fontSize-16: 16px;
$fontSize-17: 17px;
$fontSize-18: 18px;
$fontSize-20: 20px;
$fontSize-21: 21px;
$fontSize-22: 22px;
$fontSize-23: 23px;
$fontSize-24: 24px;
$fontSize-25: 25px; 
$fontBase: 'Poppins', sans-serif;

// shadow
$shadow: 0px 6px 7px rgba(188, 188, 188, 0.16);;
$shadow-navbar: 0px 4px 4px rgba(183, 183, 183, 0.47);

// border radius
$radius-sm: 5px;
$radius-lg: 10px;


@mixin flex($display, $justify, $align, $dir) {
    display: $display;
    justify-content: $justify;
    align-items: $align;
    flex-direction: $dir ; 
    
}

@mixin size($w, $h) {
    width: $w;
    height: $h;
} 
/** 
<Col xl={3} lg={4} md={6} sm={12}   className='                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 -danger'>
    <h1>shzloka</h1>
</Col>
 */