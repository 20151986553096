@import '../../style/varibale';

.app__project {
    .header {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: auto;

        span {
            width: 100%;
            text-align: center;
            
            font-style: normal;
            font-weight: 700;
            font-size: 56px;
            line-height: 84px;
            text-transform: capitalize;
            color: #404040;
        }

        p {
            font-size: 22px;
            line-height: 33px;
            text-align: center;

        }
    } 
}

/* Slider.css */

.slide-item {
    position: relative;
}

.image-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorGrey-0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.slide-item:hover .overlay {
    opacity: 1;
}

.overlay h3,
.overlay p {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0;
    padding: 5px;
}