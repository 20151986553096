@import '../../style/varibale';

.app__jops {
    background-color: white;
    min-height: 100vh;

    .app__jops-m {
        padding-top: 150px;

    }

    input {
        border-radius: 8px !important;
        background: rgba(217, 217, 217, 0.14) !important;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
        outline: brown !important;
        border: $mainColor !important;
        padding: 10px 20px !important;
    }

    .form {
        margin-bottom: 50px;
    }
}

.btn__porfile {
    background-color: #000;
    width: 100%;
    height: 45px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    margin: 0 !important;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    span {
        font-size: 17px;

        font-weight: 300;
    }

    svg {
        path {

            overflow: hidden !important;
            // fill: #fff;
            stroke: #fff
        }

        // margin-bottom: 4px !important;
    }
}

.error {
    width: 100% !important;
    margin-top: 0.25rem !important;
    font-size: .875em !important;
    color: #dc3545 !important;
}