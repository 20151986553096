@import '../../style/varibale';

.app__services {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Services1,
    .Services2,
    .Services3 {
        display: flex;
        width: 100% !important;
        flex-direction: row;
    }

    .Services1 {
        display: flex;
        width: 100% !important;
        flex-direction: row;

        // align-items: ;
        .item1 {
            border-radius: 38px 0px 53px;
            border: 1px solid $colorGrey-0;
        }

        .item2 {
            border-radius: 0px 38px 0px 52px;
        }
    }

    .Services2 {
        display: flex;
        width: 100% !important;
        flex-direction: row;

        // align-items: ;
        .item3 {
            border: 1px solid $colorGrey-0;
            border-radius: 0px 52px 0px 38px;
            border-top: none;

        }

        .item5 {
            border: 1px solid $colorGrey-0;
            border-radius: 0px 38px 0px 52px;
        }
    }

    .Services3 {
        display: flex;
        width: 100% !important;
        flex-direction: row;

        // background-color: red;
        justify-content: flex-end;

        // align-items: ;
        .item6 {
            border: 1px solid $colorGrey-0;
            border-radius: 0px 52px 0px 38px;
            border-right: none;

        }

        .item7 {
            border: 1px solid $colorGrey-0;
            border-radius: 52px 0px 38px;
            border-top: none;

        }
    }

}

.item1,
.item2,
.item3,
.item5,
.item6,
.item7 {
    width: 190px;
    height: 190px;
    border: 1px solid $colorGrey-0;
    display: flex;
    justify-content: center;
    align-items: start;
    padding-left: 30px;
    flex-direction: column;
    cursor: pointer;


    &:last-child {
        border-left: none;
    }

    span {

        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 30px;
        text-transform: capitalize;
        color: #404040;
        margin-top: 15px;
        user-select: none;
    }
}

.item1Ar {
    padding-right: 30px;
}

.item4 {
    width: 190px;
    height: 190px;
    border: 1px solid none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 38px 0px 52px 0px;

    span {

        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 30px;
        text-transform: capitalize;
        color: #404040;
        margin-top: 15px;
    }

    svg {
        width: 50px;
        height: 50px;
    }



}

.app__services-content {
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;

    h1 {
        color: $mainColor;
    }

    span {

        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 84px;
        /* identical to box height */
        user-select: none;

        text-transform: capitalize;

        color: #404040;
    }

    p {
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 33px;
    }
}

.click__item {
    background: #2D2D2D;
    border: 1px solid #000000;

    span {

        color: white;
    }

    svg {
        path {
            fill: $mainColor;
        }
    }
}

.click__item-service {
    background: rgba(255, 255, 255, 0.698);
    border: 1px solid transparent;

    span {

        color: #2D2D2D;
    }

    svg {
        path {
            fill: $mainColor;
        }
    }
}

.Services4 {
    display: none;


    .item8,
    .item9 {
        width: 190px;
        height: 190px;
        border: 1px solid $colorGrey-0;
        display: flex;
        justify-content: center;
        align-items: start;
        padding-left: 30px;
        flex-direction: column;
        cursor: pointer;


        &:last-child {
            border-left: none;
        }

        span {

            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 30px;
            text-transform: capitalize;
            color: #404040;
            margin-top: 15px;
            user-select: none;
        }
    }

    .item8 {
        border: 1px solid $colorGrey-0;
        border-radius: 0px 52px 0px 38px;


    }

    .item9 {
        border: 1px solid $colorGrey-0;
        border-radius: 38px 0px 53px 0px;

    }

    .click__item {
        background: #2D2D2D;
        border: 1px solid #000000;

        span {
            color: white !important;
        }

        svg {
            path {
                fill: $mainColor;
            }
        }
    }
}


@media screen and (max-width:700px) {

    .Services2_lg,
    .Services3_lg {
        display: none !important;
    }

    .Services4 {
        display: flex !important;
    }

    .item6 {
        border-right: 1px solid $colorGrey-0 !important;

    }

    .app__services {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
        padding: 0 150px !important;
    }
}

@media screen and (max-width:600px) {


    .click__item-service {
        background: rgba(255, 255, 255, 0.698);
        border: 1px solid transparent;

        span {

            color: #2D2D2D;
        }

        svg {
            path {
                fill: $mainColor;
            }
        }
    }

    .item1,
    .item2,
    .item3,
    .item5,
    .item6,
    .item10,
    .item11,
    .item7 {
        width: 190px;
        height: 190px;
        border: 1px solid $colorGrey-0;
        display: flex;
        justify-content: center;
        align-items: start;
        padding-left: 30px;
        flex-direction: column;
        cursor: pointer;


        &:last-child {
            border-left: none;
        }
    }

}

.app__services_details {
    .main-title-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;

        a {
            color: #fff;
            text-transform: lowercase;
            text-decoration: underline !important;
            opacity: 0.8;
        }

        .app__title {
            width: 100%;
            text-align: center;

            h1 {
                margin-bottom: 0 !important;
            }

            p {
                margin: 0 !important;
                margin-top: 10px !important;
            }
        }
    }
}