@import '../../../style/varibale';

.app__WhatWeDo {
    direction: ltr !important;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin-top: 50px !important;
    position: relative;
    margin-bottom: 50px !important;
    overflow: hidden;
    position: relative;

    .app__title {

        p {
            color: #404040;

            text-align: center;
            font-size: 22px;
            text-transform: capitalize;
            width: 70%;
            margin: auto;
            line-height: 1.6;

            @media screen and (max-width:767px) {
                width: 90%;
            }
        }
    }


    .aniamtion2 {
        position: absolute;

    }

    .aniamtion2 {
        bottom: -0px;
        left: 0;
        z-index: -1 !important;

        @media screen and (max-width:960px) {
            display: none !important;
        }
    }

    .text-p-left {
        position: absolute;
        left: 0%;
        top: 0;

        svg {
            width: 150px;

            @media screen and (max-width:1000px) {
                display: none !important;
            }
        }
    }

    .text-p-right {
        position: absolute;
        right: 0%;
        top: 100px;

        svg {
            width: 180px;

            @media screen and (max-width:1000px) {
                display: none !important;
            }
        }
    }

}

.img__RightClick {
    position: relative;
    width: 60% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    @media screen and (max-width:1000px) {
        width: 95% !important;
    }

    @media screen and (max-width:600px) {
        width: 99% !important;
    }

    svg {
        width: 100%;
        height: 300%;
        margin: 50px auto;
    }

    .servie_1,
    .servie_2,
    .servie_3,
    .servie_4,
    .servie_5 {
        position: absolute;
        width: 230px;
        height: 230px;
        background-color: white;

        .title__service {
            @media screen and (max-width:860px) {
                font-size: 16px !important;
            }

            @media screen and (max-width:600px) {
                font-size: 10px !important;
            }
        }

        @media screen and (max-width:860px) {
            width: 150px;
            height: 150px;
        }

        @media screen and (max-width:600px) {
            width: 100px;
            height: 100px;
        }
    }

    .servie_1 {
        top: 10px;
        left: -20px;

        @media screen and (max-width:1000px) {
            top: 10px;
            left: -0px;
            background-color: white;
        }
    }

    .servie_2 {
        top: 26%;
        right: 0;

        @media screen and (max-width:600px) {
            top: 28%;
            right: 0;
            background-color: white;
        }
    }


    .servie_3 {
        top: 40%;
        left: 0;

    }

    .servie_4 {
        top: 72%;
        left: 0%;
        background-color: transparent;

        @media screen and (max-width:600px) {
            top: 75% !important;
            left: 10%;
        }

        @media screen and (max-width:960px) {
            top: 70%;
        }
    }

    .servie_5 {
        bottom: 0;
        right: 0%;
        padding: 10px !important;

        @media screen and (max-width:600px) {
            background-color: white;
            bottom: 10px;
            right: 0%;

        }

        @media screen and (max-width:600px) {
            bottom: 10px;
            right: -20px;
            background-color: white;
        }
    }

}

.p_details_data {
    p {
        background-color: red($color: #000000);
        color: #fff !important;
        text-align: justify;
        font-size: 22px;
        text-transform: capitalize;
        margin: auto;
        line-height: 1.6; 
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
}