@import '../../../style/varibale';

.app__service_home {
    min-height: 100vh;

    .services_container {
        width: 85%;
        margin: auto;
    }


    .app_services_header {

        span {
            color: #C4AB73;
            font-family: $fontBase !important;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            position: relative;

            @media screen and (width < 767px) {
                font-size: 30px;

            }

            &::after {
                content: '';
                position: absolute;
                background: #000;
                width: 100%;
                height: 1px;
                left: 0;
                bottom: 0px;
            }
        }

        p {
            color: #000;
            text-align: center;
            font-family: $fontBase !important;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: 80%;

            @media screen and (width < 767px) {
                font-size: 16px;
                width: 100%;

            }
        }
    }

    .servicess_mt {
        margin-top: 120px;

        @media screen and (width < 1000px) {
            margin-top: 0px;
        }

        .services_content {
            @media screen and (width < 767px) {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .icons {
                max-width: 57px;
                max-height: 75px;
                margin-bottom: 14px;

                img {
                    width: 100%;
                }

                @media screen and (width < 767px) {
                    max-width: 67px;
                    max-height: 65px;
                }
            }

            span {
                color: #000;
                font-family: $fontBase !important;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;

                @media screen and (width < 767px) {
                    font-size: 18px;
                }
            }

            p {
                color: #000;
                font-family: $fontBase !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                margin-top: 19px;
            }

            .btn_more {
                margin-top: 30px;

                span {

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    width: fit-content;
                    color: #C4AB73;
                    font-family: $fontBase !important;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: capitalize;
                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: '';
                        position: absolute;
                        background: #C4AB73;
                        width: 100%;
                        height: 1px;
                        left: 0;
                        bottom: -5px;
                    }
                }
            }

            [dir="ltr"] .btn_more svg {
                transform: rotate(-180deg);
            }

        }
    }
}

.services_content_other {
    span {
        color: #000;
        text-align: right;
        font-family: $fontBase !important;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: capitalize;
    }

    @media screen and (width < 767px) {
        padding: 50px 20px !important;
    }

    svg {
        width: 30px;
        height: 30px;
    }
}


.servicess_mt .service_1 {
    border: 1px solid $footerColor !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $footerColor !important;

    }
}

.servicess_mt .service_2 {
    border: 1px solid $footerColor !important;
    border-top: none !important;

    @media screen and (width < 992px) {
        border-top: none !important;
        border-left: none !important;
    }

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $footerColor !important;

    }
}

.servicess_mt .service_3 {
    border: 1px solid $footerColor !important;

    border-left: none !important;
    border-right: none !important;
    border-top: none !important;


    @media screen and (width < 992px) {
        border-left: 1px solid #000 !important;
        border-right: none !important;
    }

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $footerColor !important;

    }
}

.servicess_mt .service_4 {
    border: 1px solid $footerColor !important;

    border-left: none !important;
    border-bottom: none !important;
    border-top: none !important;

    @media screen and (width < 992px) {
        border-left: none !important;
    }

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $footerColor !important;

    }
}

.servicess_mt .service_5 {
    border: 1px solid $footerColor !important;

    border-left: none !important;
    border-bottom: none !important;
    border-top: none !important;

    @media screen and (width < 992px) {
        border-right: none !important;
    }

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $footerColor !important;

    }
}

.servicess_mt .service_6 {
    border: none !important;


    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $footerColor !important;
    }
}




[dir="ltr"] .servicess_mt .service_1 {
    border-left: none !important;
    border-top: none !important;
}

[dir="ltr"] .servicess_mt .service_2 {
    border-top: none !important;
}

[dir="ltr"] .servicess_mt .service_3 {
    border-right: none !important;
    border-top: none !important;
}

[dir="ltr"] .servicess_mt .service_4 {
    border-left: none !important;
    border-bottom: none !important;

    @media screen and (width < 992px) {
        border-left: none !important;
    }

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $mainColor !important;

    }
}

[dir="ltr"] .servicess_mt .service_5 {
    border-bottom: none !important;

    @media screen and (width < 992px) {
        border-right: none !important;
    }

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $mainColor !important;

    }
}

[dir="ltr"] .servicess_mt .service_6 {
    border-bottom: none !important;
    border-right: none !important;

    @media screen and (width < 767px) {
        border: none !important;
        border-bottom: 1px solid $mainColor !important;
    }
}


[dir="ltr"] .servicess_mt .service_6 {
    svg {
        transform: rotateY(180deg);
    }
}

.servicess_mt {
    ul {
        li {
            margin-top: 10px;
        }
    }
}

.list_ar {
    ul {
        list-style-type: none;
        padding-right: 0;

        li {
            font-family: Cairo;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;

            @media screen and (width < 900px) {
                font-size: 14px;
            }


            position: relative;
            padding-right: 30px;
            margin-bottom: 10px;
            margin-top: 13px;

            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 9px;
                width: 20px;
                height: 20px;
                background-image: url('../../../assets/SVG/check.svg');

                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}

.list {
    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            font-family: Cairo;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;

            position: relative;
            padding-left: 30px;
            margin-bottom: 10px;
            margin-top: 13px;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 6px;
                width: 20px;
                height: 20px;
                background-image: url('../../../assets/SVG/check.svg');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}

.custom-cms-container {
    color: white;
    background: rgba(20, 30, 33, 1);
    padding: 100px 30px;

    @media screen and (width <900px) {
        padding: 60px 10px;
    }

}

.custom-cms-title {
    font-family: cairo;
    font-size: 44px;
    font-weight: 700;

    @media screen and (width <900px) {
        font-size: 30px;
        line-height: normal;

    }
}

.custom-cms-subtitle {
    font-family: cairo;
    font-size: 54px;
    font-weight: 700;
    line-height: 96px;
    text-align: justify;

    @media screen and (width <900px) {
        font-size: 40px;
        line-height: normal;
    }
}

.custom-cms-description {
    font-family: cairo;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    text-align: justify;
    color: #fff !important;
    opacity: 0.8;

    @media screen and (width <900px) {
        font-size: 16px;
        line-height: normal;
    }
}

.advantages-title {
    font-family: cairo;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;

    @media screen and (width <900px) {
        font-size: 30px;
        line-height: normal;
    }
}

.custom-cms-advantages {
    list-style: none; // إزالة النقط الافتراضية من القائمة
    padding: 0;

    @media screen and (width <900px) {
        margin: 0 !important;
        padding: 0 !important;
    }

    li {
        margin-top: 35px;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        text-align: justify;
        color: rgba($color: #fff, $alpha: 0.7) !important;

        @media screen and (width <900px) {
            font-size: 20px;
        }

        strong {
            color: rgba($color: #fff, $alpha: 1) !important;
            margin-top: 35px;
            font-family: cairo;
            font-size: 24px;
            font-weight: 300;
            line-height: 36px;
            text-align: justify;

        }
    }

}

.custom-cms-advantages li {
    margin-bottom: 15px;
    font-size: 16px;
}

.advantage-number {
    font-family: cairo;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: justify;
    color: $mainColor;

    @media screen and (width <900px) {
        font-size: 20px;
    }

}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100% !important;
        margin-top: 100px;
    }
}

.content_crm {
    width: 85% !important;

    margin: auto;

    @media screen and (width <900px) {
        width: 95% !important;
    }
}

.tools-web-container {

    padding: 130px 40px;
    width: 90%;
    margin: auto;

    @media screen and (width <900px) {
        width: 95% !important;
    }

    h2 {
        font-size: 2.5em;
        font-weight: bold;
        color: #333;

        @media screen and (width <900px) {
            font-size: 2em;
            line-height: normal;

        }
    }

    .highlight {
        color: #ff6600;
    }

    .description {
        font-size: 1.2em;
        margin: 20px 0;
        color: #666;
        line-height: 1.6;
    }

    .tools-icons {
        display: flex;
        justify-content: space-around;

        flex-wrap: wrap;
        margin-top: 40px;
        gap: 30px;
    }

    .tool img {
        width: 100px;
        /* الحجم أكبر قليلاً ليطابق التصميم */
        height: 100px;
        object-fit: contain;
    }

    .tool {
        flex: 1;
        max-width: 150px;
        /* لتحديد حجم الأيقونات في الحاوية */
        display: flex;
        justify-content: center;
    }
}

.bg_Languages {
    @media screen and (width < 900px) {
        padding-top: 80px;
    }
    background-color: #030608;

    span {
        color: #fff !important;
    }

    li {
        color: #fff;
    }

    .servicess_mt .service_1 {
        border: none !important;

        @media screen and (width < 767px) {
            border: none !important;
            border-bottom: 1px solid #fff !important;

        }
    }

    .servicess_mt .service_2 {
        border: 1px solid #fff !important;
        border-bottom: none !important;
        border-top: none !important;

        @media screen and (width < 992px) {
            border-top: none !important;
            border-left: none !important;
        }

        @media screen and (width < 767px) {
            border: none !important;
            border-bottom: 1px solid #fff !important;
        }
    }

    .servicess_mt .service_3 {
        border: none !important;

        @media screen and (width < 992px) {
            border-left: 1px solid #000 !important;
            border-right: none !important;
        }

        @media screen and (width < 767px) {
            border: none !important;
            border-bottom: 1px solid #fff !important;
        }
    }

    header {
        .header_span {
            color: $mainColor !important;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.13px;
            text-decoration: underline;
        }

        .header_p {
            span{
                color: $mainColor !important;
            }
            margin-top: 20px;
            font-family: Poppins;
            font-size: 36px;
            font-weight: 700;
            line-height: 54px;
             
            color: #fff !important;
        }
        .last_p{
            color: #fff !important;
            opacity: 0.6;
        }
    }
}