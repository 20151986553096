.page__loading-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #151515;
    padding: 0 10px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #151515;
    padding: 0 10px;
    overflow: hidden;
    perspective: 1000px;

    span {
        
        font-style: normal;
        font-weight: 700;
        font-size: 250px;
        line-height: 450px;
        user-select: none; 

        // background: linear-gradient(180deg, rgba(217, 217, 217, 0.08) 0%, rgba(217, 217, 217, 0) 100%);
        background: linear-gradient(180deg, #FA6409 0%, rgba(255, 188, 152, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;


        @media screen and (max-width:1300px) {
            font-size: 200px;
        }
        @media screen and (max-width:1115px) {
            font-size: 175px;
        }
        @media screen and (max-width:1000px) {
            font-size: 150px;
        }
        @media screen and (max-width:840px) {
            font-size: 125px;
        }
        @media screen and (max-width:730px) {
            font-size: 100px;
        }

        @media screen and (max-width:600px) {
            font-size: 100px;
        }

        @media screen and (max-width:600px) {
            font-size: 60px; 
        }

    }
}


.page__loading-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #ffffff6e 10%, transparent 45%);
    transform: translateZ(-1px) scale(2);
    opacity: 0.8;
    animation: rotateBackground 4s linear infinite;
  }
  
  @keyframes rotateBackground {
    0% {
      transform: translateZ(-1px) scale(2) rotateZ(0deg);
    }
    100% {
      transform: translateZ(-1px) scale(2) rotateZ(360deg);
    }
  }