@import '../../style/varibale';


.header__page {
    height: 600px;
    position: relative;

    .total-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

    }

    .layer-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; 
        background: rgba(10, 10, 10, 0.5); 
    }

    .main-title-page {
        display: flex;
        // justify-content: center !important;
        // align-items: center !important;

        h1 {
            color: white;
        }

        p {
            margin-top: 20px !important;
            color: rgba(255, 255, 255, 0.926) !important;
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: lighter !important;
            line-height: normal !important;
            text-transform: capitalize !important;
            // width: 90% !important; 

            @media screen and (max-width:767px) {
                font-size: 16px !important;
                width: 100% !important;
            }
        }
    }
}