@import '../../../style/varibale';

.app__draw {
  direction: ltr !important;
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 100vh !important;
  flex-direction: column;
  margin-top: 150px !important;
  position: relative;
  margin-bottom: 200px !important;

  .title {
    position: absolute;
    top: 24%;
    transform: translateY(-50%);

    h1 {
      user-select: none;
      // font-family: Combo, cursive;
    }

    .right__click-title {
      // background-color:  #EDF2F8;
      background: linear-gradient(180deg, #80A8EA 0%, #FA6409 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

    }

    @media screen and (max-width:1000px) {
      display: none;
    }
  }

  .aniamtion1 {
    position: absolute;
    left: 0;
    bottom: -190px;
    z-index: -1 !important;

    @media screen and (max-width:1070px) {
      display: none;
    }
  }

  .aniamtion2 {
    position: absolute;
    right: 10px;
    top: -300px;

    @media screen and (max-width:1070px) {
      display: none;
    }


  }

  .circle {
    position: relative;
    width: 0px;
    height: 0px;
    border-radius: 0% 60% 60% 40%;
    border: 2px solid $mainColor;
    border-left: 0px solid transparent;

  }

  .circle2 {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 0% 100% 0% 0%;
    border: 2px solid $mainColor;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
    transform: rotate(180deg);
    bottom: 65%;
    left: 0px;

    .dot4 {
      bottom: 98.8%;
      left: -5px;
      // background-color: green;
    }

    .service4 {
      z-index: 999 !important;

      background-color: hsla(0, 0%, 100%, .25) !important;
      backdrop-filter: blur(2px) !important;
      -webkit-backdrop-filter: blur(2px) !important;
      position: absolute;
      top: -45%;
      right: -35%;
      transform: translateY(-50%);
      transform: rotate(-180deg);
      padding: 10px 0;
      border-radius: 10px;
      transition: all 0.5s;
      cursor: pointer !important;

      &:hover {
        background-color: rgba($color: $mainColor, $alpha: 0.2) !important;
        // transform: scale(1.2, 1.2) !important;
        transform: rotate(-180deg) !important;
        padding: 10px;
      }

      @media screen and (max-width:1130px) {
        top: -45%;
        right: -25%;
      }

      @media screen and (max-width:767px) {
        top: -50%;
        right: -35%;
      }

      img {
        height: 150px;
        width: 150px;

        @media screen and (max-width:1130px) {
          height: 120px;
          width: 120px;
        }

        @media screen and (max-width:767px) {
          height: 100px;
          width: 100px;
        }
      }

      .img-service4 {
        height: 150px;
        width: 150px;

      }
    }
  }

  .circle3 {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 0% 0% 0% 0%;
    border: 2px solid $mainColor;
    border-bottom: 0px solid transparent;
    border-top: 0px solid transparent;
    border-right: 0px solid transparent;
    top: 0%;


    .dot5 {
      top: 98.8%;
      left: -5px;
      // background-color: blue;
    }

    .service5 {
      position: absolute;
      top: 80%;
      right: -20%;
      transform: translateY(-50%);
      background-color: hsla(0, 0%, 100%, .25);
      // background-color: rgba($color: $mainColor, $alpha: 0.15);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      padding: 10px 0;
      border-radius: 10px;


      @media screen and (max-width:1130px) {
        top: 70% !important;
        right: 0% !important;
      }

      @media screen and (max-width:990px) {
        top: 70% !important;
        right: -50% !important;
      }

      @media screen and (max-width:767px) {
        top: 65% !important;
        right: -70% !important;
      }

      img {
        height: 150px;
        width: 150px;

        @media screen and (max-width:1130px) {
          height: 100px;
          width: 100px;
        }
      }

      .img-service5 {
        height: 150px;
        width: 150px;

      }

      @media screen and (max-width:1140px) {
        top: 74%;
        right: 0%;
      }
    }
  }

  .animate-circle {
    width: 600px;
    height: 350px;
    transition: width 1.5s, height 1.5s;
    transition-delay: 1s;
  }

  .animate-circle2 {
    width: 700px;
    height: 400px;
    top: 65%;
    left: 0px;
    transition: width 2s, height 2s, top 2s;
    transition-delay: 1s;

  }

  .animate-circle3 {
    width: 700px;
    height: 400px;
    top: 65%;
    transition: width 3s, height 2.5s, top 3s;
    transition-delay: 1s;
  }

  @media screen and (max-width:1000px) {
    .animate-circle {
      width: 60%;
      height: 350px;
      // border: 2px solid $mainColor;
    }

    .animate-circle2 {
      width: 80%;
      height: 400px;

    }

    .animate-circle3 {
      width: 70%;
      height: 400px;

    }
  }


  @media screen and (max-width:800px) {
    .animate-circle {
      width: 60%;
      height: 280px;
      // border: 2px solid $mainColor;
    }

    .animate-circle2 {
      width: 80%;
      height: 400px;

    }

    .animate-circle3 {
      width: 70%;
      height: 400px;

    }
  }


  .dot4,
  .dot5,
  .dot1,
  .dot2,
  .dot3 {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $mainColor;
    border-radius: 50%;
    transition-delay: 3s;
    animation: moveDots 2s infinite;
  }

  .dot1 {
    top: -5.5px;
    left: -4px;
    // background-color: rebeccapurple;
  }

  .service1 {
    position: absolute;
    top: -30%;
    left: -45%;
    // background-color: rebeccapurple;
    background-color: hsla(0, 0%, 100%, .25);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 10px 0;
    border-radius: 10px;

    @media screen and (max-width:1130px) {
      top: -30%;
      left: -20%;
    }

    @media screen and (max-width:767px) {
      top: -45%;
      left: -20%;
    }

    img {
      height: 150px;
      width: 150px;

      @media screen and (max-width:1130px) {
        height: 100px;
        width: 100px;
      }
    }

    .img-service1 {
      height: 150px;
      width: 150px;
    }
  }

  .service2 {
    z-index: 12 !important;
    position: absolute;
    top: 20%;
    right: -45%;
    transform: translateY(-50%);
    // background-color: crimson;
    background-color: hsla(0, 0%, 100%, .25);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 10px 0;
    border-radius: 10px;

    @media screen and (max-width:1130px) {
      top: 15%;
      right: -25%;
    }

    @media screen and (max-width:700px) {
      top: 20% !important;
      right: -30% !important;
    }

    img {
      height: 150px;
      width: 150px;

      @media screen and (max-width:1130px) {
        height: 120px;
        width: 120px;
      }

      @media screen and (max-width:700px) {
        height: 100px;
        width: 100px;
      }
    }

    .img-service2 {
      height: 150px;
      width: 150px;

    }
  }

  .service3 {
    z-index: 99997 !important;
    position: absolute;
    bottom: -30%;
    left: -45%;
    background-color: hsla(0, 0%, 100%, .25);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 10px 0;
    border-radius: 10px;

    @media screen and (max-width:1130px) {
      bottom: -30%;
      left: -25%;
    }

    @media screen and (max-width:700px) {
      bottom: -35%;
      left: -25%;
    }

    img {
      height: 150px;
      width: 150px;

      @media screen and (max-width:1130px) {
        height: 120px;
        width: 120px;
      }


      @media screen and (max-width:700px) {
        height: 100px;
        width: 100px;
      }
    }

    .img-service3 {
      height: 150px;
      width: 150px;


    }

  }

  .dot2 {
    top: 45%;
    right: -5.5px;
    transform: translateY(-50%);
    // background-color: chartreuse;
  }

  .dot3 {
    bottom: 33.5%;
    left: -4px;
    transform: translateY(-50%);
  }
}

@media screen and (max-width:600px) {

  // .service5 {
  //   position: absolute;
  //   top: 50% !important;
  //   right: -65% !important;


  //   img {
  //     height: 100px;
  //     width: 100px;
  //   }

  //   .img-service5 {
  //     height: 100px;
  //     width: 100px;

  //   }

  // }
}

@keyframes moveDots {
  0% {
    transform: scale(0);
  }

  10% {
    transform: scale(0.2);
  }

  15% {
    transform: scale(0.3);
  }

  20% {
    transform: scale(0.4);
  }

  25% {
    transform: scale(0.5);
  }

  30% {
    transform: scale(0.6);
  }

  35% {
    transform: scale(0.7);
  }

  35% {
    transform: scale(0.8);
  }

  40% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}


.app__animation-right {
  .app__title {
    p {
      color: #404040;
      text-align: center;
      font-size: 22px;
      text-transform: capitalize;
      width: 70%;
      margin: auto;
      line-height: 1.6;

      @media screen and (max-width:767px) {
        width: 90%;
      }
    }
  }
}

.title__service {
  color: #404040;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: capitalize;
  user-select: none;

  @media screen and (max-width:767px) {
    font-size: 16px;

  }
}

.service1,
.service2,
.service3,
// .service4,
.service5 {

 
    cursor: pointer !important;
    // transition: all 0.5s; 

    &:hover {
      background-color: rgba($color: $mainColor, $alpha: 0.2) !important;
      padding: 10px !important;
      // transform: scale(1.2, 1.2) !important;
    }
  

}