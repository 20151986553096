@import '../../style/varibale';

// app__contact
.app__contact {
    // min-height: 100vh;
    overflow-x: hidden;

    .Group {
        display: flex;
        flex-direction: row;

    }
}

.group__conect {
    
    display: flex !important; 
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;
    @media screen and (max-width:767px) { 
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
    }
    .email,
    .callphone {
        display: flex;
        align-items: center;
        gap: 5px;

        svg { 
            path{

                fill: $mainColor;
            }
            padding: 4px;
        }

        a {
            // font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;

            color: #414141;
        }
    }

    @media screen and (max-width:767px) {
        display: flex !important;
        flex-direction: column !important;
        margin-bottom: 20px;
    }

}

.app__contact-content {
    .container-content {
        // width: 92%;
        margin: auto;

        .title {
            
            font-style: normal;
            font-weight: 700;
            font-size: 56px;
            line-height: 84px;
            /* identical to box height */

            text-transform: capitalize;

            color: #404040;
            margin-bottom: 20px;
        }

        .input-style {
            background: rgba(217, 217, 217, 0.14) !important;
            border-radius: 5px !important;
        }

        .label-style {

            
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height */

            text-transform: capitalize;

            color: #404040;
        }

        .btn__submit-form {
            padding: 10px 40px;
            margin: 30px 0;
            border-radius: 5px;
            border-color: transparent;
            background-color: $colorGrey-0;
            color: $colorLight;
        }

        .send-message {
            .Group {
                // margin-bottom: 30px;
                margin-top: 23px;

                .download-profile {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
            

                    color: #FFFFFF;
                    background-color: $mainColor;
                    padding: 10px 20px;
                    border-radius: 5px;
                    border-color: transparent;
                    transition: all 0.5s;
                    height: 50px;
                    &:hover {
                        box-shadow: none !important;
                    }
                }

                @media screen and (max-width:767px) {
                    display: flex !important;
                    flex-direction: column !important;
                }
            }

        }
    }
}
.map-contactus{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px ;
    margin: auto;
    height:80% !important;
}
.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 100% ; 
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 100% ;
    border-radius: 5px !important;
}

.gmap_iframe {
    height: 500px !important;
}