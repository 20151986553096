@import '../../style/varibale';


// start secrion our clicnts
.app__ourClicnts {
    .content {
        span {

            font-style: normal;
            font-weight: 700;
            font-size: 56px;
            line-height: 84px;
            text-transform: capitalize;
            color: #404040;
        }

        p {
            font-size: 16px;
            line-height: 33px;
            font-weight: 500 !important;
        }
    }
}

.modal__clients {
    .image-gallery-icon .image-gallery-right-nav {
        display: none !important;
    }

    .image-gallery-icon .image-gallery-left-nav {
        display: none !important;
    }

    height: 100% !important;

}

.image__clints {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.clients-img {
    img {
        max-width: 300px;
        max-height: 200px;
        object-fit: contain;
        padding: 15px 20px; 
        border-radius: 10px; 
        background-color:white;
    }


}

.modal__image-clients { 
    margin-bottom: 20px; 
    .image-gallery-thumbnail {
        margin-top: 120px;
        /* يمكنك ضبط القيمة حسب المسافة المطلوبة */
    }

    .image-gallery-thumbnail-inner { 
        border-radius: 4px; 
    }

    .image-gallery-thumbnail-image {
        border-radius: 4px;
        padding: 15px;
    }

    .image-gallery-right-nav,
    .image-gallery-left-nav
    {
        background-color: #000000ac;
        display: none !important;
    }
}