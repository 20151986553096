 @import '../../../style/varibale';

 .property-1-default {
     height: 430px;
     padding: 35px;
     background: #404040;
     border-radius: 24px 23px 0px 21px;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     transition: all 0.5s;

     &:hover {
         background-color: $mainColor;
     }

     .our-portfolio {
         color: #ffffff;
         font-weight: 700;
         font-size: 64px;
         font-family: "Cairo", sans-serif;
         position: relative;
         width: 100%;
     }

     .gallery {
         width: 70%;

         .know-more {
             color: #ffffff;
             text-align: left;
             font-weight: 400;
             font-size: 20px;
             font-family: "Cairo", sans-serif;
             position: relative;
             cursor: pointer;

             .line-34 {
                 border-style: solid;
                 border-color: #ffffff;
                 border-width: 1px 0 0 0;
                 width: 38px;
                 height: 0px;
                 position: absolute;
                 left: -25%;
                 top: 50%;

             }
         }
     }

     .galleryAr {
         width: 70%;

         .know-more {
             color: #ffffff;
             text-align: right;
             font-weight: 400;
             font-size: 20px;
             font-family: "Cairo", sans-serif;
             position: relative;
             cursor: pointer;

             .line-34 {
                 border-style: solid;
                 border-color: #ffffff;
                 border-width: 1px 0 0 0;
                 width: 38px;
                 height: 0px;
                 position: absolute;
                 right: -25%;
                 top: 50%;

             }
         }
     }
 }

 .modal__image {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
     height: 100vh !important;
     // overflow: hidden !important;
 }

 .projects {

     .custom-image-gallery-slide img {
         width: 900px !important;
         height: 500px !important;
         object-fit: contain !important;

         @media screen and (max-width:767px) {
             width: 100% !important;
             height: 100% !important;
         }
     }
     .image-gallery-description {
         color: white !important;
         font-size: 30px !important;
         background-color: rgba($color: #000, $alpha: 0.8) !important;
         border-radius: 8px !important;
         left: 20px !important;
     }
    
     .image-gallery-descriptionAr {
         color: white !important;
         font-size: 30px !important;
         background-color: rgba($color: #000, $alpha: 0.8) !important;
         bottom: 70px;
         right: 20px !important;
         line-height: 1;
         padding: 10px 20px;
         position: absolute;
         white-space: normal;
         border-radius: 8px !important;
    
    
     }
 }


 .custom-image-gallery .image-gallery-icon {
     &:hover {

         color: $mainColor !important;
     }
 }

 .custom-image-gallery .image-gallery-play-button::before,
 .custom-image-gallery .image-gallery-right-nav::before,
 .custom-image-gallery .image-gallery-left-nav::before {
     background-color: $mainColor !important;
 }

 .custom-image-gallery .image-gallery-bullet.active {
     background-color: $mainColor !important;
     border-color: $mainColor !important;
 }

 @media screen and (max-width:767px) {
     .css-h4y409-MuiList-root {
         height: fit-content !important;
     }
 }

 .app__project_details {
     overflow: hidden;

     .slick-slider {
         display: block !important;
     }

     .app__title-details {
         // background-color: $mainColor !important;
         background-color: $footerColor;

         .body {
             padding-top: 70px !important;
             padding-bottom: 70px !important;
             width: 90%;
             margin: auto;

             h6 {
                 color: #FFF;
                 font-size: 40px;
                 font-style: normal;
                 font-weight: 700;
                 line-height: normal;
                 text-transform: capitalize;
             }

             p {
                 color: #FFF !important;
             }
         }
     }

     .app__content {
         .body {
             margin-top: 50px !important;
             margin-bottom: 100px !important;
             width: 90%;
             margin: auto;
         }
     }

 }


 .vertical-timeline::before {
     content: '';
     position: absolute;
     top: 0;
     left: 18px;
     height: 100%;
     width: 4px;
     background: #24282C !important;
 }

 .duration {
     font-size: 32px !important;

     .duration_date {
         color: #ffffffcb !important;

         font-size: 25px;
     }


 }

 .duration_date_description {
     color: #ffffffcb !important;
     font-size: 20px;
 }

 .slider__Project {
     margin-top: 150px !important;
 }

 .image-container {
     cursor: pointer;
 }

 .overlay_project {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.8);
     display: flex;
     align-items: center;
     justify-content: center;
     color: white;
     font-size: 24px;
     font-weight: bold;
     border-radius: 5px;
 }

 .app__project_details-title {
    .header__page .layer-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $footerColor;
    }
     .app__title {
         backdrop-filter: blur(6px);
         -webkit-backdrop-filter: blur(6px);
         background-color: rgba($color: #000000, $alpha: 0.4) !important;
         border-left: 5px solid #FA6409;
         border-right: 5px solid #FA6409;
         width: 100% !important;

         h1 {
             padding-top: 15px;
             height: 100%;
             text-align: center !important;
             margin: auto !important;
         }
     }
 }

 .card__image {
     margin: auto;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;

     .product-detail-container {
         display: flex;
         gap: 100px;
         margin: 40px;
         margin-top: 60px;
         color: #324d67;
     }

     .product-detail-image {
         padding: 20px;
         cursor: pointer;
         border-radius: 10px;
         background-color: #ebebeb;
         transition: 0.3s ease-in-out;

         img {
             width: 400px;
             height: 400px;

         }
     }


     .product-detail-image:hover {
         background-color: $footerColor;
         // background-color: $mainColor;
         // background-color: #f02d34;
     }

     .small-images-container {
         display: flex;
         gap: 10px;
         margin-top: 20px;

     }

     .small-image {
         border-radius: 8px;
         background-color: #ebebeb;
         width: 80px;
         height: 80px;
         padding: 5px;
         cursor: pointer;
     }

     .selected-image {
         // background-color: $footerColor;
         // background-color: $mainColor;
         background-color: #f02d34;
     }

     .reviews {
         color: #f02d34;
         margin-top: 10px;
         display: flex;
         gap: 5px;
         align-items: center;
     }

     .overlay_small-image {
         h1 {

             font-size: 25px !important;
         }
     }


 }

 .Logo_filter {
     backdrop-filter: blur(6px);
     -webkit-backdrop-filter: blur(6px);
     background-color: rgba($color: #000000, $alpha: 0.4) !important;
     //  border-left: 3px solid #FA6409;
     padding: 3px 8px !important;
     width: 100px;
     height: 80px !important;
 }

 .btn_download{
    display: flex ;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
 }