@import '../../../style/varibale';

// app__About US
.app__about {
  img {
    object-fit: cover !important;
  }

  .content {
    /*    h1 {
      font-size: 56px;
      // line-height: 84px;
      color: $mainColor;
    } */

    p {
      font-size: 22px;
      line-height: 33px;
    }
  }

  .summary {
    // padding: 0 45px;
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: start;
    @media screen and (max-width:767px) {
      width: 100%;
    }
    .project {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      width: 100%;

      .title { 
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        // line-height: 33px;
        text-transform: capitalize;
        color: #6D6D6D;
      }

      .score {

        font-style: normal;
        font-weight: 700;
        // font-size: 50px; 
        color: #33323b;
        background-clip: text; 
        font-size: 56px;
        font-weight: 700;
        line-height: 100%;
      }

      .years {

        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        // letter-spacing: 0.55em;
        text-transform: capitalize;
        color: #000000;

      }
    }
  }

  .app__Who_we_Are {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 30px;
      padding: 10px 2px;

      // padding: 0 10px;
      @media screen and (max-width:767px) {
        height: 400px;
      }

      span {

        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        text-transform: capitalize;
        color: #404040;
      }

      p {
        line-height: 33px;
        text-align: center;
      }
    }
  }

  .seconed-conteent {

    @media screen and (max-width:1050px) {
      flex-direction: column-reverse !important;
    }

    @media screen and (max-width:767px) {
      flex-direction: column-reverse;
    }
  }

  .seconed-conteent2 {

    @media screen and (max-width:1050px) {
      flex-direction: row;
    }

    @media screen and (max-width:767px) {
      display: flex;
      flex-wrap: nowrap !important;
      flex-direction: column-reverse !important;
    }
  }
}

@media screen and (max-width:1200px) {
  .app__about {
    width: 70% !important;
  }

  .summary {
    width: 70%;
  }
}

.about__Container {
overflow: hidden !important;
  .container {
    width: 79% !important;
    margin: auto;
  }
}


.about_block {
  display: none !important;
}

@media screen and (max-width:767px) {
  .app__about {
    width: 95% !important;
  }

  .content {
 
    p {
      font-size: 16px !important;
    }
  }

  .summary {
    width: 90%;
    flex-direction: column;
  }

  .about_none {
    display: none !important;
  }

  .about_block {
    display: block !important;
  }
}


.about__Container {
  padding: 50px 0 !important;

  .app__Who_we_Are {
    .border-leftEn {
      border: 1px solid rgba(0, 0, 0, 0.61);
      // border-left: none;
    }

    .border-leftAr {
      border: 1px solid rgba(0, 0, 0, 0.61);
      // border-right: none;
    }


    .border-rightEn {
      border: 1px solid rgba(0, 0, 0, 0.61);
      /*      border-top: none;
            border-right: none; */
    }

    .border-rightAr {
      border: 1px solid rgba(0, 0, 0, 0.61);
      /*       border-top: none;
            border-left: none; */
    }

    .content {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      flex-direction: row;
      height: 500px;

      .about__header {
        height: 100%; 
        .about__content {
          height: 100%; 
          display: flex;
          flex-direction: column; 
          justify-content: space-around;
          padding: 20px 0;

          .sumarrry {
            display: flex;
            gap: 30px;
            flex-direction: column;

            .more {

              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 36px;
              text-transform: capitalize;
              text-decoration: underline !important;
              color: #404040;
              cursor: pointer;
            }

            .number {

              font-style: normal;
              font-weight: 700;
              font-size: 40px;
              line-height: 60px;
              text-transform: capitalize;
              color: white;
              text-shadow:
                1px 1px 0 $mainColor,
                -1px -1px 0 $mainColor,
                1px -1px 0 $mainColor,
                -1px 1px 0 $mainColor;
            }

          }
        }


        &:hover {
          transition: all 1s;
          background-color: $mainColor;

          .about__content {

            .title {
              color: $colorLight;
            }

            .sumarrry {

              .more {
                color: $colorLight;
              }

              .number {
                color: $mainColor;
                text-shadow:
                  1px 1px 0 $colorLight,
                  -1px -1px 0 $colorLight,
                  1px -1px 0 $colorLight,
                  -1px 1px 0 $colorLight;


              }

            }
          }
        }
      }

      .body {
        height: 100%;
        // margin-left: 40px;
        display: flex;
        flex-direction: column;
        // height: 580px;
        justify-content: space-around;
        padding: 20px 0;

        p {
          font-family: 'Cairo', sans-serif !important;
          font-size: 16px !important;
          font-weight: 400;
          color: #6F6F6F !important;
          text-align: start;
        }
      }
    }
  }
}

@media screen and (max-width:767px) {
  .content-rtl {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}

[dir="ltr"] .about_img1 {
  img {
    border-radius: 10px 10px 0px 10px !important;

  }
}

[dir="rtl"] .about_img1 {
  img {
    border-radius: 10px 10px 10px 0px !important;

  }
}

[dir="ltr"] .about_img2 {
  img {

    border-radius: 0px 10px 10px 10px !important;

  }
}

[dir="rtl"] .about_img2 {
  img {
    border-radius: 10px 0px 10px 10px !important;

  }
}

.project {
  border-radius: 30px 30px 30px 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  padding: 25px 30px;
  user-select: none;

  .title {
    width: 100%;
    text-align: center;
  }

  .score {
    position: relative; 
    .plus {
      position: absolute;
      left:-40%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.sum1,
.sum2,
.sum3 {
  background-color: white;
 
  border-radius: 8px;

}

.sum2 {
  margin: 0 20px;
}