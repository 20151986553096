@import '../../style/varibale';

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&display=swap');

.app__Footer {
    border-radius: 15px 15px 0px 0px !important;
    background-image: url('../../assets/Images/FooterBg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*   min-width: 500px; */
    position: relative;
    padding-bottom: 0px;
    overflow: hidden;
    padding-top: 50px;



    .container {
        width: 100% !important;
    }

    .overlayer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(28, 26, 25, 0.93);
        z-index: -1 !important;
    }

    .app__foooter-content {
        margin-top: 100px;
    }

    .content1 {
        h1 {
            color: $mainColor;

            @media screen and (max-width:'1200px') {
                font-size: 30px !important;
            }
        }

        .app__footer-p {
            font-style: normal !important;
            font-weight: 300 !important;
            font-size: 17px !important;
            line-height: 36px !important;
            text-transform: capitalize !important;
            color: #FFFFFF;
        }

        .social {
            margin-top: 37px;

            span {
                font-style: normal;
                font-weight: 300;
                font-size: $fontSize-20;
                line-height: 30px;
                /* identical to box height */
                text-transform: capitalize;

                color: #FFFFFF;

            }

            .social__icons {
                margin-top: 20px;
            }
        }
    }

    .content2,
    .content3,
    .content4 {

        h1 {
            color: $mainColor;

            font-style: normal;
            font-weight: 600;
            font-size: $fontSize-25;
            line-height: 42px;


        }
    }


}

.footer-ui {
    ul {
        list-style: none;
        margin: 0 !important;
        padding: 5px !important;
        display: flex;
        // gap: 26px;
        flex-direction: column;

        a {
            color: white !important;
        }

        li {
            font-style: normal;
            font-size: $fontSize-16;
            line-height: 50px;
            text-transform: capitalize;
            color: #FFFFFF;
            cursor: pointer;
            position: relative;
            width: fit-content;

             &::after {
                content: '';
                position: absolute;
                bottom: 2px; 
                width: 0%;
                height: 1px;
                background-color: $mainColor;

            }

            &:hover {
                &::after {
                    transition: all 0.5s;
                    content: '';
                    position: absolute;
                    bottom: 2px; 
                    width: 100%;
                    height: 1px;
                    background-color: $mainColor;

                }
            }

            [dir="ltr"] &::after { 
                left: 0;  
            }

            [dir="ltr"] &:hover {
                &::after { 
                    left: 0;  
                }
            }

            [dir="rtl"] &::after { 
                right: 0;  
            }

            [dir="rtl"] &:hover {
                &::after { 
                    right: 0;  
                }
            }
        }
    }
}

.line__footer {
    border: 0.5px solid #FFFFFF;
}


.app__footer-contact-us {
    .content-Contact-Us {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 15px;
        color: $colorLight-0 !important;

        p {
            font-size: $fontSize-16;
            color: $colorLight-0 !important;
        }
    }

    gap: 25px !important;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;

    @media screen and (max-width:767px) {
        flex-direction: column;
        left: 20px;

    }

    .phoneIcons {
        transition: all 0.5s;
        background-color: white;
        padding: 8px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background-color: $footerColor;

            svg {
                path {
                    fill: $mainColor;
                }
            }
        }

        .phoneIcons-email {
            &:hover {
                background-color: $footerColor;

                svg {
                    path {
                        fill: #2D2D2D !important;
                        stroke: #FFFFFF !important;
                    }
                }
            }
        }
    }




    .phoneIcons-email {
        transition: all 0.5s;
        background-color: white;
        padding: 8px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background-color: $footerColor;

            svg {
                path {
                    fill: #2D2D2D !important;
                    stroke: $mainColor !important;
                }
            }
        }
    }

}

// .Form__footer {
//     margin-top: 30px;

//     input {

//         background: rgba(255, 255, 255, 0.17);
//         border-radius: 5px;
//         border: none;
//         outline: none;
//         height: 35px;
//         color: #FFFFFF;
//         padding-left: 20px;
//         width: 100%;
//     }

//     textarea {
//         background: rgba(255, 255, 255, 0.17);
//         border-radius: 5px;
//         border: none;
//         outline: none;
//         width: 100%;
//         color: #FFFFFF;
//         resize: none;
//         padding-left: 20px;
//         padding-top: 5px;
//     }
// }
.footer__copyright {
    p {
        color: #FFF !important;
        font-size: $fontSize-16 !important;

        text-transform: capitalize;
    }
}

.footer-top {
    position: relative;

    .top {
        position: absolute;
        top: -25px;
        right: 25px;
        background-color: $mainColor;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
        user-select: none;

        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        transition: all 0.5s;

        &:hover {
            box-shadow: none !important;
        }

        @media screen and (max-width:767px) {
            width: 40px;
            height: 40px;
            top: -20px;

            svg {
                width: 15px;
            }
        }
    }
}